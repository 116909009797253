
























import { Vue, Component } from "vue-property-decorator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { MicIcon, MicOffIcon, VideoIcon, VideoOffIcon, PhoneIcon } from "vue-feather-icons";
import { connect, createLocalAudioTrack, createLocalVideoTrack } from "twilio-video";
import Bowser from "bowser";

import AusterButton from "@/components/AusterButton.vue";
import AusterInput from "@/components/AusterInput.vue";
import AusterInputModal from "@/components/AusterInputModal.vue";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: { ClipLoader, AusterButton, AusterInput, AusterInputModal, MicIcon, MicOffIcon, VideoIcon, VideoOffIcon, PhoneIcon },
})
export default class AppointmentDetails extends Vue {
  appointmentId: number = null;

  twilioRoom: any = null;

  videoAvailable = false;
  videoEnabled = false;

  microphoneAvailable = false;
  microphoneEnabled = false;

  remoteParticipantPresent = false;
  remoteParticipantName = "";

  screenHeight = window.innerHeight; // mitigation to URL Bar Resizing behavior on mobile: https://developers.google.com/web/updates/2016/12/url-bar-resizing

  mounted() {
    this.appointmentId = parseInt(this.$route.params.id);

    // handle window resize
    window.addEventListener("resize", () => {
      this.screenHeight = window.innerHeight;
    });

    this.loadVideoRoom()
      .catch(error => console.error(error));

    // check browser to show warning for Chrome on iOS
    const userAgentInfo = Bowser.parse(window.navigator.userAgent);
    if (userAgentInfo?.os?.name === "iOS" && userAgentInfo?.browser?.name === "Chrome") {
      this.$vs.notification({ title: "Navegador incompatível", text: "O navegador Chrome no iOS não suporta chamadas de vídeo. Utilize o Safari para continuar.", color: "#FF6767", duration: "none" });
    }
  }

  destroyed() {
    if (this.twilioRoom) {
      this.twilioRoom.disconnect();
    }
  }

  async loadVideoRoom() {
    try {
      const videoRoom = await appointmentModel.getVideoRoom(this.appointmentId);
      this.remoteParticipantName = this.$store.state.activeUser?.role === "PSYCHOLOGIST" ? videoRoom.patientUser?.name : videoRoom.psychologistUser?.name;

      // create local tracks separately and handle missing permissions
      const localTracks = [];

      // audio track
      try {
        const localAudioTrack = await createLocalAudioTrack();
        localTracks.push(localAudioTrack);
        this.microphoneAvailable = true;
        this.microphoneEnabled = true;
      } catch {
        this.microphoneAvailable = false;
        this.microphoneEnabled = false;
      }

      // video track
      try {
        const localVideoTrack = await createLocalVideoTrack();
        (this.$refs.localVideo as any).appendChild(localVideoTrack.attach()); // display local video track
        localTracks.push(localVideoTrack);
        this.videoAvailable = true;
        this.videoEnabled = true;
      } catch {
        this.videoAvailable = false;
        this.videoEnabled = false;
      }

      // connect to room
      this.twilioRoom = await connect(videoRoom.twilioAccessToken, { sid: videoRoom.twilioVideoRoomSid, tracks: localTracks });

      // attach participants who are already in the room
      this.twilioRoom.participants.forEach(participant => this.attachRemoteParticipantTracks(participant));

      // attach participants who connect later
      this.twilioRoom.on("participantConnected", participant => this.attachRemoteParticipantTracks(participant));

      // detect remote participant disconnection and remove media elements
      this.twilioRoom.on("participantDisconnected", () => this.clearRemoteMedia());

      // handle local participant disconnection
      this.twilioRoom.on("disconnected", room => {
        // remove elements
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
          publication.track.stop(); // release hardware resources
        });
        // go to appointment page
        this.$router.replace(`/dash/consulta/${this.appointmentId}`);
        this.$vs.notification({ title: "Consulta finalizada", text: "Sua consulta terminou.", color: "success" });
      });

    } catch (error) {
      console.error(error);
      this.$vs.notification({ title: "Erro ao abrir a sala de vídeo", text: `Verifique sua conexão e tente novamente. Mais informações: ${error}.`, color: "#FF6767" });
    }
  }

  attachRemoteParticipantTracks(participant: any) {
    this.remoteParticipantPresent = true;
    participant.tracks.forEach(publication => {
      if (publication.track) {
          this.setRemoteMedia(publication.track.attach());
      }
    });
    participant.on("trackSubscribed", track => this.setRemoteMedia(track.attach()));
  }

  setRemoteMedia(trackElement: any) {
    const remoteMedia: any = this.$refs.remoteMedia;
    remoteMedia.appendChild(trackElement);
  }

  clearRemoteMedia() {
    this.remoteParticipantPresent = false;
    const remoteMedia: any = this.$refs.remoteMedia;
    while (remoteMedia.firstChild) {
      remoteMedia.removeChild(remoteMedia.firstChild)
    }
  }

  toggleVideo() {
    if (!this.twilioRoom) {
      return;
    }
    if (!this.videoAvailable) {
      this.$vs.notification({ title: "Câmera não disponível", text: "Para utilizá-la, habilite a permissão em seu navegador e recarregue a página.", color: "#FF6767", duration: 10000 });
      return;
    }
    this.videoEnabled = !this.videoEnabled;
    this.twilioRoom.localParticipant.videoTracks.forEach(publication => {
      if (this.videoEnabled) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
  }

  toggleMicrophone() {
    if (!this.twilioRoom) {
      return;
    }
    if (!this.microphoneAvailable) {
      this.$vs.notification({ title: "Microfone não disponível", text: "Para utilizá-lo, habilite a permissão em seu navegador e recarregue a página.", color: "#FF6767", duration: 10000 });
      return;
    }
    this.microphoneEnabled = !this.microphoneEnabled;
    this.twilioRoom.localParticipant.audioTracks.forEach(publication => {
      if (this.microphoneEnabled) {
        publication.track.enable();
      } else {
        publication.track.disable();
      }
    });
  }

  disconnect() {
    this.twilioRoom.disconnect();
  }
}
